/**
 * 更新イベント。
 */
export class UpdateEvent extends Event {
    /** 更新されたレコードの ID */
    readonly id: string;

    /**
     * 新しいインスタンスを初期化します。
     * @param type イベント種別
     * @param id 更新されたレコードの ID
     */
    constructor(type: string, id: string) {
        super(type);
        this.id = id;
    }
}
